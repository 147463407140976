import Slide1Src from '../../assets/slide1.png';
import Slide2Src from '../../assets/slide2.png';
import Slide3Src from '../../assets/slide3.png';
import Slide4Src from '../../assets/slide4.png';
import Slide5Src from '../../assets/slide5.png';
import Slide6Src from '../../assets/slide6.png';
import Slide7Src from '../../assets/slide7.png';
import Slide8Src from '../../assets/slide8.png';
import Slide9Src from '../../assets/slide9.png';
import Slide10Src from '../../assets/slide10.png';

const SlideData = [
  {
    id: 1,
    name: 'Adidas Alpha Bounce Experience',
    url: Slide1Src,
  },
  {
    id: 2,
    name: 'Arctic Cat VR Ride Along',
    url: Slide2Src,
  },
  {
    id: 3,
    name: 'Little Caesars Arena Projection Mapping',
    url: Slide3Src,
  },
  {
    id: 4,
    name: 'Adidas Powerpad Product Trial',
    url: Slide4Src,
  },
  {
    id: 5,
    name: 'Wells Fargo Center Stadium Concourse Experience',
    url: Slide5Src,
  },
  {
    id: 6,
    name: 'Nike Training Club Technology Package',
    url: Slide6Src,
  },
  {
    id: 7,
    name: 'Comerica Bank Concourse Activation',
    url: Slide7Src,
  },
  {
    id: 8,
    name: 'DHC Interactive Tabletop Display',
    url: Slide8Src,
  },
  {
    id: 9,
    name: 'Huntington Bank Concourse Branding',
    url: Slide9Src,
  },
  {
    id: 10,
    name: 'Harman Moonroof Display VR Experience',
    url: Slide10Src,
  },
];

export default SlideData;
